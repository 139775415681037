import React, { useState } from "react"
import { graphql } from "gatsby"
import Seo from "../../components/common/seo"
import Layout from "../../components/common/layout"
import Modal from "react-modal"
import Hero from "../../components/members-zone/hero.js"

const MembersZone = ({ data }) => {
    
  Modal.setAppElement("#___gatsby")

    return (
        <div className="membersZone">
            <Layout>
                <Seo title="Members Zone" />
                <Hero />
                  <div className="membersZoneWrapper soloPage indMember">
            <h2>COMMB Data Report</h2>
            <p className="lead">These videos are best viewed at 720p resolution and full screen. Place your cursor at the bottom of the video screen to access these menu selections.</p>
            <hr />
            <br />
            <div className="videosWrapper">
              <div>
                <h3>Vidéo 1</h3>
                <p>Aperçu du Rapport de données COMMB</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/jIljj_MJdg0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>         
              <div>
                <h3>Vidéo 2</h3>
                <p>Créer un rapport pour les groupes de produits ou les réseaux d’un marché</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Ig9rGGM-4nI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div>
                <h3>Vidéo 3</h3>
                <p>Créer un rapport pour un grand marché en indiquant en détails les SDR</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/jYFG6SoLFFg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div>
                <h3>Vidéo 4</h3>
                <p>Comment repérer les changements de produits par rapport à la vague d’affichage précédente et établir les parts de marché des produits</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/m-HkHgQ4Ds4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>          
              <div>
                <h3>Vidéo 5</h3>
                <p>How to create a report without subtotals (table mode or single worksheet) so the data can easily be manipulated (en anglais seulement)</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/vsrkF_bXbfI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div>
              <h3>Vidéo 6</h3>
                <p>Créer un rapport séparant les produits d’affichage extérieur numériques et conventionnels</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/8cddYbHyETI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div>
              <h3>Vidéo 7</h3>
                <p> How to run a report for a custom outdoor company market (en anglais seulement)</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/jmRi93jbTic" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div>
                <h3>Vidéo 8</h3>
                <p>How to run a PATTISON market report with proximate CSDs (en anglais seulement)</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/sxdGny9F_O0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              </div> 
            </div>
            </Layout>
      </div>
      
    )
}

export default MembersZone