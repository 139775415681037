import React from "react"
import { Link } from "gatsby"

const Hero = () => {
  return (
    <div className="common_banner_section members-zone">
      <div className="container">
        <div className="banner_center_section membersZoneTitle">
          <Link to="/members-zone"><h1>Members Zone</h1></Link>
          <div className="banner_info"></div>
        </div>
      </div>
    </div>
  )
}

export default Hero